@import "config";

.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  background: #fff;
  padding-top: var(--offset-top);
  display: flex;
  flex-direction: column;

  &-enter {
    transform: translateX(-100%);
  }

  &-enter-active {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

  &-exit {
    transform: translateX(0);
  }

  &-exit-active {
    transform: translateX(-100%);
    transition: transform 0.2s ease-out;
  }
}
