@import "config";

:root {
  --main-padding-x: 30px;
  --main-width: 1200px;
}

.desktop {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;

  // custom-scroll
  &.scroll-layout > div > div:nth-child(3) {
    z-index: 1000;
  }

  &-top-hidden {
    height: 0;
    width: 0;
    scroll-margin-top: 105px;
    scroll-snap-margin-top: 105px;
  }

  &__title {
    color: #000;
    font-weight: 500;
    font-size: 20px;
    display: block;
    // position: sticky;
    // top: 0;
    // z-index: 99;
    background-color: #fff;

    &.product-list__header {
      scroll-margin-top: 105px;
      scroll-snap-margin-top: 105px;
    }
  }

  &__back {
    border: solid 1px #cccfd1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 40px;
    width: fit-content;
    padding: 5px 12px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
  }

  &__grid-wrapper {
    display: grid;
    padding: 0;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: 10px;
  }

  &-main-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-header {
    // width: calc(var(--main-width) + var(--main-padding-x));
    width: 100%;
    max-width: var(--main-width);
    min-width: 1000px;
    padding: 19px 0 15px 0;
    display: flex;
    min-height: 55px;

    @media (max-width: 1230px) {
      padding: 19px 30px 15px 30px;
    }

    &__delivery-to {
      display: flex;
      flex-direction: row;

      @media (max-width: 1200px) {
        flex-direction: column;
      }
    }

    & > div:first-child {
      min-width: 230px;
      @media (max-width: 1000px) {
        min-width: 85px;
      }
    }
  }

  &-search {
    z-index: 16;
    border: 1px solid #cccfd1;
    box-shadow: none !important;
    min-height: auto;

    &--active {
      & .desktop-search__suggests {
        z-index: 16;
        display: block;
        position: absolute;
        margin-top: 5px;
        width: calc(100% - 2px);
      }
    }

    &__suggests {
      border-radius: 5px;
      background: #fff;
    }

    &__input {
      $placeholder_color: #45af8f;

      &::-webkit-input-placeholder {
        color: $placeholder_color;
      }

      &::-moz-placeholder {
        color: $placeholder_color;
      }

      &:-moz-placeholder {
        color: $placeholder_color;
      }

      &:-ms-input-placeholder {
        color: $placeholder_color;
      }
    }
  }

  &-menu-container {
    opacity: 0;
    max-height: 390px;
    z-index: 20;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;
    transform: scaleY(0);
    transform-origin: top;

    &._open {
      transform: scaleY(1);
      opacity: 1;
    }

    & div.icon {
      margin-left: 12px;
    }

    @keyframes close {
      0% {
        opacity: 1;
        max-height: 390px;
      }
      99% {
        opacity: 1;
        max-height: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes open {
      0% {
        opacity: 1;
        max-height: 0;
      }
      100% {
        opacity: 1;
        max-height: 390px;
      }
    }
  }

  &-chat {
    width: 42px;
    height: 42px;
    pointer-events: auto;
    display: flex;
    border-radius: 50%;
    background-color: map-get($colors, "white");
    border: 1px solid #cccfd1;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: -47px;

    & > img {
      width: 32px;
      align-self: center;
      margin: auto;
    }

    &-container {
      z-index: 400;
      position: absolute;
      pointer-events: auto;
      display: block;
      border-radius: 14px;
      width: 320px;
      height: 467px;
      filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.1));
      background: #fff;
      overflow: hidden;
      bottom: 0;
      right: 0;
    }

    &-header {
      background: #e35531;
      width: 100%;
      height: 59px;
      padding: 17px;
      display: flex;
    }

    &__wrapper {
      position: sticky;
      z-index: 220;
      pointer-events: none;
      right: 0;
      bottom: 50px;
    }
  }

  &-categories__preview {
    height: 24px;
    border-radius: 3px;
    padding-right: 12px;
  }

  &-categories__preview-2 {
    height: 24px;
    width: 24px;
    border-radius: 3px;
    margin-right: 12px;
    background-size: auto 120%;
    background-position: 100% 100%;
  }

  &-main {
    // width: calc(var(--main-width) + var(--main-padding-x));
    width: 100%;
    max-width: 1200px;
    display: flex;
    padding-bottom: 20px;
  }

  & .checkout__button {
    border-radius: 0 0 8px 8px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-top: 0;
    z-index: $zindex_cart_board;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    padding: 9px 8px calc(9px + var(--offset-bottom));
  }

  &--discounts {
    &-list {
      overflow: auto;
      box-sizing: border-box;
      @media (max-width: 1260px) {
        max-width: 530px;
      }
      @media (max-width: 1110px) {
        max-width: 380px;
      }
      @media (max-width: 1078px) {
        max-width: 320px;
      }

      .product-item {
        flex-shrink: 0;
        width: 87px;
        padding: 0;
        height: auto;
        border: none;
        overflow: hidden;
        border-radius: 0;

        &__img {
          height: 56px;
        }

        &__name {
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
        }

        &__price {
          margin: 0;
          width: 100%;

          &-old {
            font-size: 11px;
          }

          &-unit {
            color: #999fa4;
          }
        }

        &__footer {
          padding-top: 4px;

          & > .d-flex {
            flex-wrap: wrap;
          }

          .splash {
            display: none !important;
          }
        }

        &__discount,
        &__trademark,
        &__bakery,
        &__bundle {
          left: 0;
          top: 3px;
        }

        &__bottom-badge {
          left: 0;
          top: 20px
        }

        &__favorite {
          transform: scale(0.8) translate(7px, -7px);
        }

        .counter {
          height: 28px !important;

          &-wrap {
            padding-top: 8px;
          }

          &-add {
            justify-content: center;
            height: 36px !important;
            font-size: 12px;
            padding: 0;

            &__price {
              font-size: 12px;
            }
          }

          &__icon {
            height: 28px !important;
          }
        }

        & + .product-item {
          margin-left: 16px;
        }
      }
    }

    &-control {
      position: absolute;
      top: 50%;
      z-index: 5;

      &._prev {
        left: -35px;
        transform: scaleX(-1);
      }

      &._next {
        right: -35px;
      }
    }
  }

  &-powered-by {
    width: 100%;
    color: #73bbe0;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  & .footer-grass {
    width: 100%;
    height: 70px;
    background-image: url($img_dir + "grass.png");
    background-repeat: repeat-x;
    background-size: contain;
    top: 100%;
    position: sticky;
    display: inline-block;
  }

  &-cart-column {
    max-height: calc(100vh - 104px - 10px);
    display: flex;
    flex-direction: column;
  }

  // custom-scroll-on-cart
  &-cart-layout > div > div:nth-child(3) {
    z-index: 1000;
  }

  &-demo-catalog {
    display: flex;
    align-items: center;
    padding: 15px 16px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    position: absolute;
    bottom: 0;
    left: -50px;
    right: 0;

    &__wrap {
      position: sticky;
      z-index: 210;
      bottom: 50px;
      left: 0;
    }

    &__text {
      margin: 0 12px;
      font-size: 13px;
      line-height: 16px;
      color: #000;
    }
  }

  &-test {
    height: 500px;
    position: sticky;
    z-index: 200;
    bottom: 0;
    background: #000;
  }

  .category-slider .category-item {
    min-height: 100px;
    padding-top: calc((min((100vw - 730px), 530px) - 32px) / 3 - 5px);
  }

  &-alert {
    margin: auto;
    right: auto;
    z-index: $zindex_dialog;
    width: 500px;
  }

  
  .desktop-chat-icon {
    color: #24594b;
  }
}

.banner {
  width: 100%;
  position: relative;
  display: flex;
  background-color: #aadb1e;
  height: 80px;
  margin: 14px 0 16px 0;

  &__constraint {
    max-width: 880px;
    display: flex;
    margin: 0 auto;
  }

  &__text {
    padding: 15px 0;
    font-size: 18px;

    & em {
      color: #fff;
      font-style: normal;
      font-weight: 600;
    }
  }

  &__img {
    margin: 0 0 0 -33px;

    & img {
      width: 178px;
      position: relative;
      top: -18px;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  &__close-icon {
    position: absolute;
    right: 130px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    color: #fff;
    font-size: 16px;
    @media (max-width: 1200px) {
      right: 50px;
    }
  }

  .goal {
    color: #fff;
    background-color: #0091da;
    font-size: 14px;
    padding: 8px 48px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
  }
}

.footer {
  margin-top: 50px;
  width: 100%;

  display: flex;
  background-color: #f44719;

  &__constraint {
    max-width: 1200px;
    min-width: 1000px;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 53px 130px 18px 130px;
    @media (max-width: 1200px) {
      padding: 53px 15px 18px 15px;
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;

    & img {
      width: 127px;
      margin-bottom: 75px;
    }
  }

  &__links {
    width: 400px;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;
    }

    a {
      color: #fff;
      font-weight: 600;
      margin-bottom: 14px;
    }
  }

  &__socials {
    margin-top: 30px;
    display: flex;
  }

  &__apps {
    height: 52px;
    width: 360px;

    & > img {
      height: 100%;
    }

    & > img:first-child {
      margin-right: 10px;
    }
  }
}
