@import "config";
@import "icon_names";

.icon {
  @include icomoonInit();

  &:after {
    pointer-events: none;
    position: relative;
    z-index: 2;
  }
}

.icon-share {
  &:before {
    content: $icon-share;
  }
}

.icon-spark {
  &:before {
    content: $icon-spark;
  }
}

.icon-clock {
  &:before {
    content: $icon-clock;
  }
}

.icon-sleep {
  &:before {
    content: $icon-sleep;
  }
}

.icon-label {
  &:before {
    content: $icon-label;
  }
}

.icon-cart {
  &:before {
    content: $icon-cart;
  }
}

.icon-sleep-outline {
  &:before {
    content: $icon-sleep-outline;
  }
}

.icon-flash-outline {
  &:before {
    content: $icon-flash-outline;
  }
}

.icon-clock-outline {
  &:before {
    content: $icon-clock-outline;
  }
}

.icon-arrow-long {
  &:before {
    content: $icon-arrow-long;
  }
}

.icon-percent {
  &:before {
    content: $icon-percent;
  }
}

.icon-discount {
  &:before {
    content: $icon-discount;
  }
}

.icon-blocked {
  &:before {
    content: $icon-blocked;
  }
}

.icon-accepted {
  &:before {
    content: $icon-accepted;
  }
}

.icon-picking {
  &:before {
    content: $icon-picking;
  }
}

.icon-payment-card {
  &:before {
    content: $icon-payment-card;
  }
}

.icon-exclamation {
  &:before {
    content: $icon-exclamation;
  }
}

.icon-sign-i {
  &:before {
    content: $icon-sign-i;
  }
}

.icon-indelivery {
  &:before {
    content: $icon-indelivery;
  }
}

.icon-tornado {
  &:before {
    content: $icon-tornado;
  }
}

.icon-star-rounded {
  &:before {
    content: $icon-star-rounded;
  }
}

.icon-pen {
  &:before {
    content: $icon-pen;
  }
}

.icon-info {
  &:before {
    content: $icon-info;
  }
}

.icon-attach {
  &:before {
    content: $icon-attach;
  }
}

.icon-plane {
  &:before {
    content: $icon-plane;
  }
}

.icon-check-long {
  &:before {
    content: $icon-check-long;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-stopwatch {
  &:before {
    content: $icon-stopwatch;
  }
}

.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}

.icon-delivery {
  &:before {
    content: $icon-delivery;
  }
}

.icon-star-fill {
  &:before {
    content: $icon-star-fill;
  }
}

.icon-fire {
  &:before {
    content: $icon-fire;
  }
}

.icon-flag {
  &:before {
    content: $icon-flag;
  }
}

.icon-favorite-fill {
  &:before {
    content: $icon-favorite-fill;
  }
}

.icon-remove {
  &:before {
    content: $icon-remove;
  }
}

.icon-attention {
  &:before {
    content: $icon-attention;
  }
}

.icon-card {
  &:before {
    content: $icon-card;
  }
}

.icon-security {
  &:before {
    content: $icon-security;
  }
}

.icon-check-box {
  &:before {
    content: $icon-check-box;
  }
}

.icon-error {
  &:before {
    content: $icon-error;
  }
}

.icon-bag {
  &:before {
    content: $icon-bag;
  }
}

.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}

.icon-chevron-left {
  transform: scaleX(-1);
  display: inline-block;
  &:before {
    content: $icon-chevron-right;
  }
}

.icon-chevron-right-thin {
  &:before {
    content: $icon-chevron-right-thin;
  }
}

.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}

.icon-profile {
  &:before {
    content: $icon-profile;
  }
}

.icon-basket {
  &:before {
    content: $icon-basket;
  }
}

.icon-support {
  &:before {
    content: $icon-support;
  }
}

.icon-faq {
  &:before {
    content: $icon-faq;
  }
}

.icon-about {
  &:before {
    content: $icon-about;
  }
}

.icon-bell {
  &:before {
    content: $icon-bell;
  }
}

.icon-gear {
  &:before {
    content: $icon-gear;
  }
}

.icon-mail {
  &:before {
    content: $icon-mail;
  }
}

.icon-filter {
  &:before {
    content: $icon-filter;
  }
}

.icon-heart {
  &:before {
    content: $icon-heart;
  }
}

.icon-heart-fill {
  &:before {
    content: $icon-heart-fill;
  }
}

.icon-minus {
  &:before {
    content: $icon-minus;
  }
}

.icon-plus {
  &:before {
    content: $icon-plus;
  }
}

.icon-check {
  &:before {
    content: $icon-check;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-arrow-back {
  &:before {
    content: $icon-arrow-back;
  }
}

.icon-flash {
  &:before {
    content: $icon-flash;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-favorite {
  &:before {
    content: $icon-fav;
  }
}

.icon-geolocation {
  &:before {
    content: $icon-geolocation;
  }
}

.icon-geolocation-fill {
  &:before {
    content: $icon-geolocation-fill;
  }
}

.icon-burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 20px;
  width: 100%;
  height: 100%;

  .dash {
    width: 100%;
    border-bottom: 2px solid currentColor;
    margin-bottom: 4px;
  }

  &:before {
    width: 100%;
    content: "";
    border-bottom: 2px solid currentColor;
    margin-bottom: 4px;
  }

  &:after {
    width: 100%;
    content: "";
    border-bottom: 2px solid currentColor;
  }
}

.icon-marker {
  &:before {
    content: $icon-marker;
  }
}

.icon-jiffy-point-check {
  &:before {
    content: $icon-jiffy-point-check;
  }
}

.icon-jiffy-point {
  &:before {
    content: $icon-jiffy-point;
  }
}

.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
