@import "config";

.dialog {
  z-index: $zindex_dialog;
  position: relative;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__body {
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 5px;
    padding:  0 20px 24px 20px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 50px);
    max-width: calc(100vw - 75px);
  }

  &__header {
    margin: 15px 0;
    line-height: 36px;
  }

  &__icon-close {
    position: absolute;
    top: 10px;
    right: 4px;
  }
}
