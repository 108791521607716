@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes cartBoardIn {
  from {
    margin-bottom: calc(-54px - var(--offset-bottom));
  }

  to {
    margin-bottom: 0;
  }
}

@keyframes bounce {
  from,
  55%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0) scaleY(1.05);
  }

  90% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  95% {
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

@keyframes rotateCW {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 500px, 0) scaleY(5);
  }

  65% {
    opacity: 1;
    transform: translate3d(0, -15px, 0) scaleY(0.9);
  }

  85% {
    transform: translate3d(0, 3px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -2px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gradient {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}
