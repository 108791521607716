@import "config";

@font-face {
  font-family: "AXtZeinaBold";
  src: url($fonts_dir + "AXtZeinaBold.woff2") format("woff2"),
  url($fonts_dir + "AXtZeinaBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AXtZeinaLight";
  src: url($fonts_dir + "AXtZeinaLight.woff2") format("woff2"),
  url($fonts_dir + "AXtZeinaLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bw Modelica";
  src: url($fonts_dir + "BwModelica-Light.woff2") format("woff2"),
  url($fonts_dir + "BwModelica-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bw Modelica";
  src: url($fonts_dir + "BwModelica-Medium.woff2") format("woff2"),
  url($fonts_dir + "BwModelica-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bw Modelica";
  src: url($fonts_dir + "BwModelica-Regular.woff2") format("woff2"),
  url($fonts_dir + "BwModelica-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bw Modelica";
  src: url($fonts_dir + "BwModelica-Bold.woff2") format("woff2"),
  url($fonts_dir + "BwModelica-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html.company-baqal {
  &:root {
    --override-font-feixen: "AXtZeinaBold", "Bw Modelica", sans-serif;
    --override-font-feixen-book: "AXtZeinaBold", "Bw Modelica", sans-serif;
    --override-font-value-serif-medium: "AXtZeinaBold", "Bw Modelica", sans-serif;
  }

  .button._primary,
  .counter-add,
  .promocode__btn,
  .counter-wrap._discount .counter-add {
    background: #fdd427;
    color: #000;
  }

  .status-widget {
    background: #fdd427;
    color: #000 !important;
  }

  .input-switch._active {
    background: #e19a0f;
  }

  .product-item__discount:before,
  .counter-wrap._discount .counter__icon {
    color: #e19a0f;
  }

  .product-item__price._discount {
    color: #000;
  }

  .product-item__price-unit,
  .product-item__price-old {
    color: #8c8b8b;
  }

  .promocode._filled {
    border-color: #fdd427;
  }

  .counter,
  .counter-wrap._discount .counter {
    border-color: #e19a0f;
  }

  .counter__icon {
    color: #e19a0f;
  }

  .checkout-instruction:before {
    background: #e19a0f;
  }

  .checkout-instruction__check {
    background: #fdd427;
    color: #000 !important;
  }

  .product-item__favorite.c-tc7 {
    color: #8c8b8b !important;
  }

  .product-item__bakery {
    color: #000;
  }

  .product-item__trademark:before,
  .radio-bullet {
    color: #fdd427;
  }

  .radio-bullet._active {
    border-color: #e19a0f;
  }

  .input-checkbox._active {
    background: #fdd427;
    border-color: #fdd427;
  }

  .input-checkbox .icon {
    color: #000;
  }

  .button._secondary {
    background: #f3f3f3;
  }

  .c-bg-dgreen {
    background: #e35531 !important;
  }

  .status-icon._active {
    background: #fdd427;
  }

  .status-icon {
    color: #8c8b8b;
  }

  .icon.c-blue {
    color: #e35531 !important;
  }
  
  .desktop-menu-icon {
    .icon {
      color: #e35531 !important;
    }
  }

  .main-header__zip .icon-chevron-right {
    margin-top: -3px;
  }

  .cart-board {
    color: #000;

    .c-dorange {
      color: #f44719 !important;
    }
  }
}
