@import "config";

html.company-swifft {
  &:root {
    --override-font-feixen: Arial, Helvetica, sans-serif !important;
    --override-font-feixen-book: Arial, Helvetica, sans-serif !important;
    --override-font-value-serif-medium: Arial, Helvetica, sans-serif !important;
  }

  .button-address {
    border: none !important;
  }

  .desktop-menu-icon {
    &:hover {
      background: rgba(38, 169, 224, 0.1);
    }

    .icon {
      color: #26a9e1;
    }
  }

  .button._primary,
  .counter-add,
  .promocode__btn,
  .counter-wrap._discount .counter-add {
    background: #26a9e1;
    color: #fff;

    .c-tad {
      color: #fff !important;
    }
  }

  .c-bg-corange,
  .promocode__icon {
    background: #26a9e1 !important;
    color: #fff !important;

    .c-tad {
      color: #fff !important;
    }
  }

  .status-widget {
    background: #26a9e1;
    color: #fff !important;
  }

  .input-switch._active {
    background: #26a9e1;
  }

  .product-item__discount:before,
  .counter-wrap._discount .counter__icon {
    color: #26a9e1;
  }

  .product-item__price._discount {
    color: #000;
  }

  .product-item__price-unit,
  .product-item__price-old {
    color: #8c8b8b;
  }

  .promocode._filled {
    border-color: #26a9e1;
  }

  .counter,
  .counter-wrap._discount .counter {
    border-color: #26a9e1;
  }

  .counter__icon,
  .c-bg-dgreen {
    color: #26a9e1;
  }

  .checkout-instruction:before {
    background: #26a9e1;
  }

  .checkout-instruction__check {
    background: #26a9e1;
    color: #fff !important;
  }

  .product-item__favorite.c-tc7 {
    color: #8c8b8b !important;
  }

  .product-item__bakery {
    color: #000;
  }

  .product-item__trademark:before,
  .radio-bullet {
    color: #26a9e1;
  }

  .radio-bullet._active {
    border-color: #26a9e1;
  }

  .input-checkbox._active {
    background: #26a9e1;
    border-color: #26a9e1;
  }

  .button._secondary {
    background: #f3f3f3;
  }

  .c-bg-dgreen,
  .c-bg-dorange {
    background: #26a9e1 !important;
  }

  .status-icon._active {
    background: #26a9e1;
  }

  .status-icon {
    color: #8c8b8b;
  }

  .icon.c-blue,
  .icon.c-dgreen,
  .desktop-chat-icon,
  .c-corange {
    color: #26a9e1 !important;
  }

  .desktop-menu-icon {
    .icon {
      color: #26a9e1 !important;
    }
  }

  .main-header__zip .icon-chevron-right {
    margin-top: -3px;
  }

  .cart-board {
    color: #000;

    .c-dorange {
      color: #8c8b8b !important;
    }
  }

  .page-header {
    &__title {
      font-weight: 600;
    }
  }

  .story {
    &-item {
      &._new {
        box-shadow: 0 0 0 1px #fff, 0 0 0 3px #26a9e1;
      }
    }
  }

  .product {
    &-card {
      .product-item__price {
        font-size: 21px;
        font-weight: 600;
      }
    }
  }

  .input-text__icon._check {
    color: #26a9e1;
  }

  .product-info__item__title,
  .product-list__header__title,
  .category-title,
  .cart-section__title,
  .text-weight-bolt {
    font-weight: 600 !important;
  }

  .orders__image {
    width: 80%;
  }

  .footer {
    background-color: #26a9e1;
  }

  .desktop-search {
    .icon {
      color: #26a9e1 !important;
    }
  }

  .button-address {
    background-color: #f2f1f0 !important;

  }

  .auth {
    background-color: #f2f1f0 !important;
    color: #333f48 !important;
  }

  .counter-wrap._discount .counter-add._sold-out,
  .counter-add._sold-out {
    background: none !important;
    color: #000 !important;
  }

  .spinner {
    background-image: url($img_dir + "spinner_white.png") !important;
  }

  .referral-code-box {
    border-color: #26a9e1;
  }

  .c-bg-salad4 {
    background-color: #9ed4f0 !important;
  }
}
