@import "config";

:root {
  --offset-top: 0px;
  --offset-bottom: 0px;
  --override-font-feixen: initial;
  --override-font-feixen-book: initial;
  --override-font-value-serif-medium: initial;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  color: #333;
  font-family: var(--override-font-feixen, $font_feixen);
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;

  @media (min-width: $layout_max_width) {
    -webkit-user-select: auto;
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  outline: 0;
  touch-action: pan-y pan-x;
  font-family: var(--override-font-feixen, $font_feixen);

  &:before,
  &:after {
    box-sizing: border-box;
    touch-action: pan-y pan-x;
  }
}

h1,
.h1 {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  font-family: var(--override-font-feixen, $font_feixen);
}

h2,
.h2 {
  padding: 0;
  margin: 0;
  font-family: var(--override-font-feixen, $font_feixen);
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
}

h3,
.h3 {
  padding: 0;
  margin: 0;
  font-family: var(--override-font-feixen, $font_feixen);
  font-weight: 500;
  font-size: 19px;
  line-height: 36px;
}

a {
  color: #333;
  text-decoration: none;

  &:hover {
    color: #333;
    text-decoration: none;
  }
}

strong {
  font-weight: 700;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333 !important;
  background-clip: content-box !important;
}

.content {
  &-layout {
    max-width: $layout_max_width;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: var(--offset-top);
  }
}

.height-100vh {
  min-height: calc(100vh - 196px - 50px);
}