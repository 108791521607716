@import "config";
@import "icon_names";

.button {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  text-decoration: none;
  color: #333;
  background: none;
  position: relative;
  flex-shrink: 0;
  border: 0;
  line-height: 1;
  font-family: var(--override-font-feixen-book, $font_feixen_book);
  cursor: pointer;

  &._primary {
    background: #24594b;
    color: #fff;
  }

  &._secondary {
    background: #e9f2ec;
    color: #000;
  }

  &._bordered {
    border: 1px solid #cccfd1;
  }

  &._block {
    display: flex;
  }

  &._disabled,
  &[disabled] {
    color: map-get($colors, "mgray") !important;
    background: #ebeced !important;
    box-shadow: none !important;
    cursor: default;
  }

  &._disabled {
    pointer-events: none !important;
  }

  &._white {
    background: #F2F2F2;
    color: #333F48;
  }
}

.input {
  &-text {
    $placeholder_color: #878786;
    border: 0;
    font-size: 14px;
    color: #333;
    background: none;
    padding: 0;
    margin: 0;

    &::-webkit-input-placeholder {
      color: $placeholder_color;
    }

    &::-moz-placeholder {
      color: $placeholder_color;
    }

    &:-moz-placeholder {
      color: $placeholder_color;
    }

    &:-ms-input-placeholder {
      color: $placeholder_color;
    }

    &__wrap {
      min-height: 48px;
      position: relative;
      background: #fff;
      border-radius: 8px;
      padding: 0 1px;

      &._active {
        .input-text__label {
          top: 3px;
          font-size: 12px;
          color: #c4c4c4;
        }
      }

      &._error {
        .input-text__border {
          background: map-get($colors, "red") !important;
        }
      }

      .input-text {
        width: 100%;
        height: 36px;
        padding: 0 12px;
        font-size: 16px;
        margin-top: 11px;
        position: relative;
        z-index: 2;
        border-radius: 8px;

        &:focus {
          & ~ .input-text__label {
            top: 3px;
            font-size: 12px;
            color: #c4c4c4;
          }
        }
      }

      .input-textarea {
        position: relative;
        z-index: 2;
      }
    }

    &__border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ebeced;
      border-radius: 8px;
      pointer-events: none;
      z-index: 1;
      transition: background .2s;

      &:before {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background: #fff;
        border-radius: 7px;
      }
    }

    &__label {
      font-size: 14px;
      color: #878786;
      transition: all 0.2s;
      pointer-events: none;
      position: absolute;
      top: 14px;
      left: 12px;
      right: 12px;
      z-index: 3;

      &._active {
        top: 3px;
        font-size: 12px;
        color: #c4c4c4;
      }

      &._shift {
        left: 48px;
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      pointer-events: none;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      transform: translateY(-50%);
      z-index: 3;

      &._check {
        right: 8px;
        color: #24594b;

        & ~ .input-text {
          padding-right: 35px;
        }

        & ~ .input-text__label {
          right: 35px;
        }
      }
    }

    &__clear {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }

  &-textarea {
    $placeholder_color: #878786;
    border: 0;
    font-size: 14px;
    color: #333;
    background: none;
    padding: 12px;
    margin: 0;
    width: 100%;
    resize: none;

    &::-webkit-input-placeholder {
      color: $placeholder_color;
    }

    &::-moz-placeholder {
      color: $placeholder_color;
    }

    &:-moz-placeholder {
      color: $placeholder_color;
    }

    &:-ms-input-placeholder {
      color: $placeholder_color;
    }
  }

  &-checkbox {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    border: 1px solid #c7c7c7;
    position: relative;
    background: #fff;
    border-radius: 5px;
    transition: border-color 0.2s, background 0.2s;

    .icon {
      width: 18px;
      height: 18px;
      position: absolute;
      top: -1px;
      left: -1px;
      color: #fff;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      transition: opacity 0.2s;
      opacity: 0;
    }

    &._active {
      border-color: #24594b;
      background: #24594b;

      .icon {
        opacity: 1;
      }
    }
  }

  &-select {
    position: relative;

    &._active {
      .input-select__val {
        label {
          display: none;
        }

        .input-select__icon {
          transform: translateY(-50%) scaleY(-1);
        }
      }

      .input-select__list {
        display: block;
      }
    }

    &._expand-up {
      .input-select__list {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: 4px;
      }
    }

    &__focus {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
      width: 1px;
      height: 1px;
    }

    &__val {
      .link-abs {
        z-index: 5;
      }

      .input-select__item {
        z-index: 2;
        position: relative;
      }

      .icon-chevron-down {
        z-index: 3;
      }
    }

    &__icon {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      pointer-events: none;
      transition: transform 0.3s;
    }

    &__list {
      position: absolute;
      top: 100%;
      background: #fff;
      left: 0;
      width: 100%;
      display: none;
      max-height: 150px;
      overflow: auto;
      border: 1px solid #ebebeb;
      z-index: 8;
      animation: fadeIn 0.3s backwards;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      margin-top: 4px;
      padding: 0 14px;
    }

    &__item {
      height: 48px;
      display: flex;
      align-items: center;
      padding-right: 40px;
      position: relative;

      & + .input-select__item {
        border-top: 1px solid #ebebeb;
      }

      .icon-accepted {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
  }

  &-switch {
    width: 40px;
    height: 20px;
    position: relative;
    border-radius: 20px;
    background: #b8c5d0;
    transition: background .3s;

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: #fff;
      transition: transform .3s ease-in-out;
    }

    &._active {
      background: #26a9e1;

      &:before {
        transform: translateX(20px);
      }
    }
  }
}

.link {
  &-abs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.img {
  &-cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-contain {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-center {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img,
    svg {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.hide-scroll-bar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.counter {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  background: #fff;
  // border: 1px solid #24594b;
  // padding: 10px;
  border-radius: 8px;
  font-family: var(--override-font-feixen-book, $font_feixen_book);

  &__icon {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #24594b;
    border-radius: 5px;
    position: relative;

    img {
      height: 14px;
      width: 14px;
    }

    &.icon-minus,
    &.icon-plus {
      &:before,
      &:after {
        content: "";
        width: 12px;
        height: 1px;
        background: currentColor;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-weight: 700;
      }
    }

    &.icon-minus {
      &:after {
        content: none;
      }
    }

    &.icon-plus {
      &:after {
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }

    &._disabled {
      pointer-events: none;
      opacity: .3;
    }
  }

  &__val {
    flex-shrink: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    width: 22px;
    color: #000;
  }

  &-wrap {
    padding-top: 10px;

    &._discount {
      .counter {
        border-color: #e35531;

        &__icon {
          color: #e35531;
        }

        &-add {
          background: #e35531;
        }
      }

      .splash {
        circle {
          fill: #e35531;
        }
      }
    }
  }

  &-add {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #fff;
    font-size: 14px;
    height: 32px;
    background: #24594b;
    line-height: 1;
    padding: 0 8px;
    border-radius: 8px;
    font-family: var(--override-font-feixen-book, $font_feixen_book);

    &__price {
      font-size: 14px;

      &-old {
        font-size: 10px;
        opacity: .7;
        margin-left: 3px;
      }
    }

    &._sold-out {
      background: none !important;
      color: #e5243f;
      font-weight: 600;
      justify-content: center;

      &:after {
        content: none;
      }
    }
  }
}

.show-all {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: map-get($colors, "blue");

  .icon {
    width: 16px;
    height: 16px;
    font-size: 10px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;

    &._active {
      transform: scaleY(-1);
    }
  }
}

.scroll {
  &-layout {
    overflow: auto;
  }

  &-top {
    position: fixed;
    z-index: $zindex_scroll_top;
    top: 0;
    width: 160px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(40px + var(--offset-top));
  }

  &-list {
    display: flex;
    overflow: auto;

    &:before,
    &:after {
      content: "";
      display: block;
      flex-shrink: 0;
      pointer-events: none;
    }

    .product-item {
      flex-shrink: 0;
      width: 94px;
      padding: 0;
      height: auto;
      border: none;
      overflow: hidden;
      border-radius: 0;

      &__img {
        height: 56px;
      }

      &__name {
        font-size: 12px;
        line-height: 16px;
      }

      &__price {
        margin: 0;
        width: 100%;

        &-old {
          font-size: 11px;
        }

        &-unit {
          color: #999fa4;
        }
      }

      &__footer {
        padding-top: 4px;

        & > .d-flex {
          flex-wrap: wrap;
        }

        .splash {
          display: none !important;
        }
      }

      &__discount,
      &__trademark,
      &__bakery,
      &__bundle,
      &__limited-stock {
        left: 0;
        top: 3px;
      }

      &__bottom-badge {
        left: 0;
        top: 20px
      }

      &__favorite {
        transform: scale(0.8) translate(7px, -7px);
      }

      .counter {
        height: 36px !important;
        border-radius: 8px;
        font-family: var(--override-font-feixen-book, $font_feixen_book);
        padding: 0;

        &-wrap {
          padding-top: 8px;
        }

        &-add {
          justify-content: center;
          height: 36px !important;
          font-size: 12px;
          border-radius: 8px;

          &__price {
            font-size: 12px;
          }
        }

        &__icon {
          height: 28px !important;
        }
      }

      & + .product-item {
        margin-left: 16px;
      }
    }

    &._16 {
      &:before,
      &:after {
        width: 16px;
        height: 16px;
      }
    }

    &._24 {
      &:before,
      &:after {
        width: 24px;
        height: 24px;
      }
    }

    &._swiper {
      overflow: visible;
      padding: 0 24px;
      width: auto !important;

      &:before,
      &:after {
        content: none;
      }

      .product-item {
        width: 87px;
      }
    }
  }
}

.spinner {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  background-image: url($img_dir + "spinner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  animation: rotateCW 1.3s linear infinite;
  pointer-events: none;

  &._white {
    background-image: url($img_dir + "spinner_white.png");
  }
}

.alert {
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 46px;
  min-height: 52px;
  font-size: 14px;
  line-height: 19px;
  z-index: $zindex_alert;
  font-weight: 500;
  box-shadow: 0 0 0 2px rgba(251, 253, 247, 0.2) inset;
  position: relative;

  &:before {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
    width: 22px;
    height: 22px;
    font-size: 22px;
    font-family: $font_icomoon;
    flex-shrink: 0;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    line-height: 1;
  }

  &._error {
    background: linear-gradient(-275.21deg, #fce9ec -0.36%, #ffcad2 143.38%);
    color: #e5243f;

    &:before {
      content: $icon-error;
    }
  }

  &._warning {
    background: linear-gradient(-96.82deg, #ffdcc1 -30.24%, #fef1e7 84.73%);
    color: #f97313;

    &:before {
      content: $icon-attention;
    }
  }

  &._success {
    background: linear-gradient(-95.54deg, #c7f7d9 -7.01%, #e7f7ed 110.12%);
    color: #0eb44d;

    &:before {
      content: $icon-check-circle;
    }
  }

  &._fixed {
    position: fixed;
    left: 24px;
    right: 24px;
    bottom: calc(24px + var(--offset-bottom));
    animation: bounceInUp 0.5s backwards;

    &-cart {
      bottom: calc(70px + var(--offset-bottom));
    }
  }

  &._no-icon {
    padding-left: 20px;

    &:before {
      display: none;
    }
  }
}

.popover {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: $layout_max_width;
  z-index: $zindex_popover;
  overflow: hidden;
  transform: translateX(-50%);

  &__bg {
    position: fixed;
    // top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    &._transparent {
      background: none;
    }
  }

  &__body {
    border-radius: 12px 12px 0 0;
    background: #fff;
    padding: 24px;
    box-shadow: 0 -2px 30px rgba(0, 0, 0, 0.15);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: calc(100vh - 50px - var(--offset-top));
    overflow: auto;

    &._handle {
      &:before {
        content: "";
        position: absolute;
        top: 8px;
        width: 32px;
        height: 4px;
        background: #f1f1f1;
        border-radius: 10px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
      }
    }

    &._max-height {
      height: calc(100vh - 50px - var(--offset-top));
    }
  }

  &-enter {
    .popover__bg {
      opacity: 0;
    }

    .popover__body {
      transform: translateY(100%) !important;
    }
  }

  &-enter-active {
    .popover__bg {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }

    .popover__body {
      transform: translateY(0) !important;
      transition: transform 0.3s ease-out !important;
    }
  }

  &-exit {
    .popover__bg {
      opacity: 1;
    }

    .popover__body {
      transform: translateY(0) !important;
    }
  }

  &-exit-active {
    .popover__bg {
      opacity: 0;
      transition: opacity 0.2s;
    }

    .popover__body {
      transform: translateY(100%) !important;
      transition: transform 0.2s !important;
    }
  }

  &-zoom {
    &-enter {
      opacity: 0;
      transform: translateX(-50%) scale(0.5);
    }

    &-enter-active {
      opacity: 1;
      transform: translateX(-50%) scale(1);
      transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    }

    &-exit {
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }

    &-exit-active {
      opacity: 0;
      transform: translateX(-50%) scale(0.5);
      transition: transform 0.2s, opacity 0.2s;
    }
  }

  &-general-error {
    background: linear-gradient(180deg, #f8fcfa 0%, #fcfef6 100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--offset-top) 24px 0;
  }
}

.progress-bar {
  height: 6px;
  position: relative;
  border-radius: 10px;
  background: #ebeced;
  overflow: hidden;
  pointer-events: none;

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    transition: width 2.8s linear;
    background: map-get($colors, "blue");
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.badge {
  font-size: 13px;
  padding: 0 12px;
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
  background: #fff;
  display: inline-block;
  vertical-align: top;
  border-radius: 32px;

  & + .badge {
    margin-left: 8px;

    &._category {
      margin-left: 28px;
    }
  }

  &._search {
    font-weight: 500;
    background: #aadb1e;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border-radius: 30px;
      background: #fff;
      pointer-events: none;
      z-index: 1;
    }

    & + .badge._search {
      margin-left: 12px;
    }

    span {
      z-index: 2;
      position: relative;
    }
  }

  &._category {
    transition: border-color 0.3s;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 0;
    font-family: var(--override-font-feixen-book, $font_feixen_book);

    &._active {
      border-bottom-color: #999fa4;
    }
  }

  &._subcategory {
    transition: background 0.3s;
    color: #333;
    font-size: 14px;
    font-family: var(--override-font-feixen-book, $font_feixen_book);

    &._active {
      background: #ebeced;
    }
  }

  &-list {
    padding-bottom: 16px;
  }
}

.promocode {
  min-height: 48px;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  transition: border-color 0.3s;

  &._filled {
    border-color: #24594b;

    .promocode__btn {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &._error {
    border-color: #f8c8cf;

    .promocode__icon.icon-close {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &._warning {
    border-color: #f8c8cf;

    .promocode__icon.icon-exclamation {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &._success {
    .promocode__icon.icon-accepted {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &._error,
  &._warning,
  &._success {
    .input-text {
      color: #cccfd1;
    }
  }

  &__btn {
    position: absolute;
    border-radius: 0 8px 8px 0;
    height: 48px;
    right: -1px;
    top: -1px;
    width: 90px;
    background: #24594b;
    color: #fff;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  &__icon {
    top: 9px;
    right: 10px;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;
  }

  .input-text {
    width: 100%;
    height: 46px;
    padding: 0 106px 0 16px;
    font-size: 16px;
    position: relative;
    z-index: 2;
    color: map-get($colors, "udgray");
    transition: color 0.3s;
  }

  .spinner {
    position: absolute;
    top: 12px;
    right: 10px;
  }
}

.radio-bullet {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #24594b;
  border: 1px solid #c7c7c7;
  flex-shrink: 0;
  transition: border-color .2s;

  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    transition: opacity .2s;
    background: currentColor;
    opacity: 0;
  }

  &._active {
    border-color: #24594b;

    &:before {
      opacity: 1;
    }
  }
}


.onboarding {
  &__dots {
    white-space: nowrap;
    text-align: center;
    width: 100%;
    margin-top: 23px;
    flex-shrink: 0;
    animation: fadeIn 0.5s backwards ease-in-out;
    pointer-events: none;
    height: 6px;
  }

  &__dot {
    display: inline-block;
    vertical-align: top;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background: #333;
    opacity: 0.3;
    transition: opacity 0.4s, width 0.4s;

    & + .onboarding__dot {
      margin-left: 4px;
    }

    &._active {
      opacity: 1;
      width: 18px;
    }
  }

  &-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(80, 80, 80, 0.5) 50%, rgba(80, 80, 80, 0) 100%);
    display: flex;
    padding: calc(30px + var(--offset-top)) 16px 85px;
    pointer-events: none;
    z-index: 3;

    &__item {
      height: 5px;
      flex-grow: 1;
      background: rgba(255, 255, 255, 0.7);
      margin: 0 1px;
      overflow: hidden;
      position: relative;

      &:first-child {
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }

      div {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: #a4d518;
      }

      &._active {
        div {
          transition: width .1s linear;
        }
      }

      &._passed {
        div {
          width: 100% !important;
        }
      }
    }
  }

  &__close {
    position: absolute;
    z-index: 4;
    top: calc(36px + var(--offset-top));
    right: 22px;
    width: 50px;
    height: 50px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 26px;
      height: 4px;
      background: #fff;
      pointer-events: none;
      border-radius: 10px;
      top: 22px;
      left: 12px;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  &-slide {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &__text {
      height: 56px;
      font-size: 46px;
      font-weight: 500;
      font-family: var(--override-font-feixen, $font_feixen);
      color: #000;
      line-height: 1;
      display: flex;
      align-items: flex-end;
      padding: 0 42px;
      font-feature-settings: "tnum", "lnum";

      &-wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15.7%, rgba(255, 255, 255, 0.75) 31.09%, rgba(255, 255, 255, 0.9) 46.03%, #fff 59.16%);
        padding: 130px 0 calc(34px + var(--offset-bottom));
        z-index: 2;

        span {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: -1;
          }
        }
      }
    }
  }

  &__control {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 5;

    &._prev {
      left: 0;
    }

    &._next {
      right: 0;
    }
  }
}

.delivery-address {
  &__marker {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -41px 0 0 -16px;
    pointer-events: none;
    width: 32px;
    transform-origin: center bottom;

    &._animate {
      animation: bounce 2s infinite ease-in-out;
    }
  }

  &__cover {
    bottom: 0;
    border-radius: 12px 12px 0 0;
    animation: cartBoardIn .2s backwards ease-out;
    padding: 20px 24px 24px;

    &._expanded {
      height: calc(100% - 50px - var(--offset-top));

      &:before {
        content: "";
        position: absolute;
        top: 7px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 45px;
        height: 4px;
        background: #cccfd1;
        border-radius: 10px;
        pointer-events: none;
      }
    }
  }

  &__input {
    &-wrap {
      border: 1px solid map-get($colors, "tf2");
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

      &._error {
        border-color: map-get($colors, "red") !important;
      }
    }
  }

  &__title {
    font-size: 21px;
    line-height: 25px;
    font-weight: 500;
  }

  &__announce {
    margin-top: 12px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
  }

  .alert {
    position: relative;
    margin-top: 14px;
    animation: fadeIn 0.3s backwards;
  }

  &__zip-text-caption {
    font-size: 12px;
  }

  &__zip-text {
    font-size: 10px;
  }

  &__geolocation {
    top: -56px;
    box-shadow: 0 4px 52px rgba(0, 0, 0, 0.1);
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__legend {
    position: absolute;
    top: 8px;
    height: 30px;
    border-radius: 60px;
    background: #fff;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    padding: 0 6px;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    color: #24594b;
    white-space: nowrap;
  }
}

.main {
  &-header {
    $header_height: 52px;

    height: $header_height;
    z-index: $zindex_main-header;

    &:before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(#{$header_height} + var(--offset-top));
      pointer-events: none;
      transition: box-shadow 0.2s;
      z-index: -1;
      background: #fff;
    }

    &__dot {
      .dash {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          top: -9px;
          right: -1px;
          width: 6px;
          height: 6px;
          background-color: #f44719;
          border-radius: 50%;
          pointer-events: none;
          box-shadow: 0 0 0 1px white;
        }
      }
    }

    &__label {
      font-size: 20px;
      font-weight: 500;
      pointer-events: none;
      line-height: 1;
      text-transform: uppercase;
    }

    &__zip {
      display: flex;
      align-items: center;
      border-radius: 4px;
      height: 30px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
    }

    .icon-search {
      transition: opacity 0.3s;

      &:not(._active) {
        pointer-events: none;
        opacity: 0;
      }
    }

    &._active {
      &:before {
        box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.filter {
  &__header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;

    .filter__title {
      margin: 0;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 32px 0 24px;
  }

  &__clear {
    font-size: 14px;
    font-weight: 500;
    color: #ffbc41;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    font-size: 16px;
    margin-right: 18px;
  }

  &__line {
    height: 1px;
    background: #f2f2f2;
    margin: 16px 0;
    pointer-events: none;
  }
}

.page-header {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 7px;
  flex-shrink: 0;
  position: relative;

  &__title {
    text-align: center;
    width: 100%;
  }

  &__right {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
    font-size: 11px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.search {
  &-dummy {
    border: none;
    background-color: #F2F0F0;
    height: 40px;

    > div {
      color: #8C8B8B !important;
    }

    > .icon-search {
      color: black !important;
    }
  }

  &-bar {
    min-height: 40px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    z-index: 70;

    &__icon-search {
      width: 40px;
      height: 40px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__wrap {
      height: 50px;
    }

    .input-text {
      padding: 0 42px;
      height: 40px;
      margin: 0;

      &::placeholder {
        color: #676767;
      }
    }

    [class^="icon"] {
      position: absolute;
      z-index: 3;
    }

    .icon-close {
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

$tabsGap: 10px;

.category {
  &-list {
    margin-top: -2px;

    &-slider {
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.05);
      z-index: 11;
    }

    &__col {
      width: 33.33%;
      flex-shrink: 0;
      padding: 10px 5px 0;

      .category-item {
        &-name {
          // font-family: $font_value_serif_medium;
          font-size: 13px;
          line-height: 15px;

          @media (min-width: 940px){
            font-size: 15px;
            font-weight: 400;
            line-height: 19px;
          }
        }
      }

      &._2 {
        width: 66.66%;

        .category-item {
          padding-top: calc(50% - 5px);

          &__name {
            font-size: 18.5px;
          }
        }
      }

      &._3 {
        width: 100%;
        max-width: 100%;

        .category-item {
          padding-top: calc((100% - 20px) / 3);

          &__name {
            font-size: 18.5px;
          }
        }
      }
    }
  }

  &-item {
    position: relative;
    padding-top: 100%;
    border-radius: 10px;
    overflow: hidden;

    &__img {
      position: absolute;
      background-position: right bottom;
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 2;
      border-radius: 10px;
    }

    &__name {
      position: absolute;
      top: 9px;
      left: 9px;
      right: 11px;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.3;
      z-index: 3;
      color: #333f48;
    }

    &__link {
      z-index: 4;
      border-radius: 10px;
    }
  }

  &-title {
    margin-top: 17px;
    font-size: 16px;
    line-height: 24px;
  }

  &-slider {
    .category-item {
      padding-top: calc(((100vw - 22px) / 100 * 33.33) - 10px);
    }
  }

  /* apply rule below for each skeleton card on category page */
  &-skeleton {
    width: 33.33%;

    @media (max-width: 360px) {
      /* for small devices we need to show two columns even for ab test */
      width: 50% !important;
    }
  }
}

.product {
  &-list {
    & + .product-list {
      .product-list__header {
        padding-top: 16px;
      }
    }

    &__header {
      font-size: 16px;
    }

    &__description,
    &__description-popover {
      font-size: 12px !important;
      padding-left: 24px;
      padding-right: 24px;

      p {
        padding: 0;
        margin: 0;

        & + p {
          margin-top: 6px;
        }
      }

      h1 {
        margin: 0;
        padding: 0;
        font-size: 19px;
        line-height: 36px;
      }

      h2 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 28px;
      }

      ul,
      ol {
        padding-left: 16px;
      }

      .oc-text-bordered {
        border-top: 1px solid #71797f;
        border-bottom: 1px solid #71797f;
        padding: 6px 0;
        margin: 6px 0;
      }
    }

    &__description {
      position: relative;
      margin: 0 -24px;
      padding-top: 125px;

      p:nth-child(3) ~ * {
        display: none;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 120px;
        object-fit: cover;
        pointer-events: none;
      }
    }

    &__description-popover {
      img {
        width: calc(100% + 48px) !important;
        margin-left: -24px;
      }
    }
  }

  &-item {
    border-radius: 8px;
    background: #fff;
    border: 1px solid #f2f2f2;
    padding: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    /* wrapper for product item in the list */
    &-wrapper {
      width: 50%;

      &.threeInOne {
        width: 33.33%;

        @media (max-width: 360px) {
          /* for small devices we need to show two columns */
          width: 50% !important;
        }

        .product-item {
          padding: 0;
          border: none;

          .counter-wrap > div {
            font-size: 12px;
          }
        }
      }
    }

    &__img {
      position: relative;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100px;
      flex-shrink: 0;
    }

    &__tesco-badge {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }

    &__name {
      font-size: 12px;
      color: #000;
      font-weight: normal;
      font-family: var(--override-font-feixen-book, $font_feixen_book);
      flex-shrink: 0;
      margin-top: 8px;
      line-height: 18px;

      &:not(._full) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        box-orient: vertical;
      }
    }

    &__price {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      flex-shrink: 0;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 5px;
      align-items: baseline;

      &._discount {
        color: #e35531;
      }

      &-old {
        font-size: 12px;
        font-weight: 400;
        color: #878786;
        margin-left: 5px;
        text-decoration: line-through;
      }

      &-unit {
        font-size: 10px;
        color: #878786;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__footer {
      margin-top: auto;
      padding-top: 8px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      position: relative;
    }

    &__favorite {
      position: absolute;
      width: 30px;
      height: 30px;
      font-size: 20px;
      top: 3px;
      right: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      transition: color 0.2s;
      border-radius: 50%;
      background: #fff;

      &.icon-heart-fill {
        font-size: 22px;
      }
    }

    &__low-stock {
      position: absolute;
      right: 0;
      bottom: -5px;
      font-size: 12px;
    }

    &__discount,
    &__bundle,
    &__trademark,
    &__bakery,
    &__limited-stock {
      width: 48px;
      height: 18px;
      position: absolute;
      pointer-events: none;
      z-index: 3;
      top: 12px;
      left: 9px;
      overflow: hidden;
      transform: rotate(-15deg);

      &:before {
        @include icomoonInit();
        content: $icon-label;
        position: absolute;
        left: 0;
        top: -16px;
        font-size: 48px;
      }
    }

    &__bundle {
      font-size: 8px;
      text-align: center;
      line-height: 17px;
      color: #24594b;

      &:before {
        color: #bde9c9;
        z-index: -1;
      }
    }

    &__limited-stock {
      font-size: 6px;
      text-align: center;
      color: #000;
      line-height: 1;
      padding: 2px 6px 0 6px;

      &:before {
        color: #FFFF24;
        z-index: -1;
      }
    }

    &__bottom-badge {
      top: 28px;
      left: 7px;
      transform: rotate(5deg);
    }

    &__discount {
      color: #fff;
      font-size: 10px;
      text-align: center;
      font-weight: 500;
      line-height: 16px;

      &:before {
        color: #e35531;
        z-index: -1;
      }
    }

    &__bakery {
      color: #24594b;
      font-size: 6px;
      text-align: center;
      font-weight: 500;
      line-height: 5px;
      padding-top: 3px;

      &:before {
        color: #ffe993;
        z-index: -1;
      }
    }

    &__trademark {
      &:before {
        color: #24594b;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url($img_dir + "logo_jiffy_white.svg") center center no-repeat;
        background-size: 22px auto;
      }
    }

    .product-card__allergy {
      top: 6px;
      left: 50%;
      font-size: 8px;
      color: #0eb44d;
      transform: translateX(-50%);

      .icon {
        width: 18px;
        height: 18px;
        background: #e7f7ed;
        font-size: 12px;
        color: #0eb44d;
        margin-right: 4px;
      }
    }
  }

  &-card {
    position: relative;
    padding-top: 8px;

    &__price-lock-badge {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    &__tesco-badge {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }

    &__icon {
      &-favorite {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 3px;
        right: 3px;
        align-items: center;
        justify-content: center;
        display: flex;
        color: #25282b;
        font-size: 24px;
        z-index: 5;
        transition: color 0.2s;
        border-radius: 50%;
        background: #fff;
      }
    }

    &__icon-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      justify-content: space-between;
      column-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(50px, 65px));

      li {
        margin-top: 18px;
        margin-right: 12px;

        img {
          width: 26px;
          display: block;
          margin: 0 auto;
        }
      }
    }

    &__body {
      padding-top: 12px;
    }

    &__line {
      height: 1px;
      background: #f2f2f2;
      margin: 0 18px;
    }

    &__links {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 12px;
    }

    &__link {
      flex-shrink: 0;
      height: 50px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      color: map-get($colors, "blue");
    }

    .product-item__name {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #000;
      margin: 0;
    }

    .product-item__price {
      font-size: 21px;
      font-weight: 500;

      &-old {
        font-size: 16px;
        font-weight: 400;
        color: #999fa4;
        margin-left: 8px;
        text-decoration: line-through;
      }

      &-unit {
        font-size: 16px;
        font-weight: 500;
        color: #999fa4;
      }
    }

    .product-item__footer {
      margin-top: 0;
      padding-top: 8px;
    }

    .product-item__trademark,
    .product-item__bakery,
    .product-item__discount,
    .product-item__bundle,
    .product-item__limited-stock {
      top: 17px;
      left: 13px;
      transform: scale(1.5) rotate(-15deg);
    }

    .product-item__bottom-badge {
      top: 40px;
      left: 11px;
      transform: scale(1.5) rotate(5deg);
    }

    .product-item__bundle {
      line-height: 16px;
    }

    .counter {
      border-radius: 8px;

      &__icon {
        width: 48px;
        height: 48px;

        &.icon-minus,
        &.icon-plus {
          &:before,
          &:after {
            width: 20px;
            height: 2px;
          }
        }
      }

      &__val {
        font-size: 16px;
        width: 34px;
        color: #000;
        font-weight: 500;
      }

      &-add {
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px;
      }
    }

    .onboarding__dots {
      margin-top: 20px;
      animation: none;
    }

    .product-slider {
      width: auto;
      margin: 0 -24px !important;
    }
  }

  &-slider {
    width: 100%;

    &__slide {
      height: 205px;
      pointer-events: none;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .swiper-slide {
      padding: 0 24px;
      opacity: 0;
      transition: opacity 0.4s ease-in;

      &.swiper-slide-active {
        opacity: 1;
      }
    }
  }

  &-info {
    &__item {
      & + .product-info__item {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid map-get($colors, "tf2");
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }

      &--low {
        background-color: map-get($colors, "backgreen");
        color: map-get($colors, "tgreen");
      }

      &--medium {
        background-color: map-get($colors, "backyellow");
        color: map-get($colors, "orange");
      }

      &--high {
        background-color: map-get($colors, "ulred");
        color: map-get($colors, "red");
      }
    }

    &__button {
      display: block;
      margin-right: 0;
      margin-left: auto;
      border: none;
      background-color: transparent;
    }

    &__line {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 6px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: map-get($colors, "linegray");
      }
    }
  }

  &-icon-close {
    position: absolute;
    top: var(--offset-top);
    right: 6px;
    width: 50px;
    height: 50px;
    font-size: 26px;
    animation: fadeIn 0.3s 0.4s backwards ease-out;
    z-index: 5;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
  }
}

.cart {
  &-address {
    &__icon {
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-item {
    min-height: 92px;

    &__timer {
      background: rgba(255, 255, 255, 0.9);
      z-index: 10;
      animation: fadeIn 0.2s backwards;
    }

    .product-item__price {
      overflow: visible;

      &-old {
        position: absolute;
        color: #c4c4c4;
        pointer-events: none;
        left: 0;
        top: 100%;
        margin-top: -3px;
        margin-left: 0;
      }
    }
  }

  &-board {
    flex-shrink: 0;
    border-radius: 8px 8px 0 0;
    background: #fff;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.05);
    z-index: $zindex_cart_board;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    padding: 8px 20px calc(8px + var(--offset-bottom));
    animation: cartBoardIn .2s backwards ease-out;
    color: #24594b;

    &__icon {
      &._popover {
        color: #fff;
        background: linear-gradient(0deg, #aadb1e -16.07%, #6abf66 100%);
        box-shadow: 0 0 0 6px #e4f3de;
      }

      &._high-demand {
        color: #af7cff;

        &._popover {
          color: #fff;
          background: linear-gradient(353.4deg, #179bc2 -220.66%, #af7cff 94.82%);
          box-shadow: 0 0 0 6px #ece6fe;
        }
      }
    }
  }
}

.payment-methods {
  white-space: nowrap;
  padding: 5px 18px 0;
  margin: 0 -18px;
  overflow: auto;

  &__item {
    display: inline-flex;
    vertical-align: top;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    transition: border-color 0.2s;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 10px;

    & + .payment-methods__item {
      margin-left: 8px;
    }

    &._active {
      border-color: #ffb000;

      .icon {
        opacity: 1;
      }
    }

    img {
      width: 50px;
      pointer-events: none;
    }

    .icon {
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #ffb000;
      pointer-events: none;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #fff;
      top: -5px;
      right: -3px;
      opacity: 0;
      display: flex;
      transition: opacity 0.3s;
    }
  }
}

.payment-methods-page {
  &__item {
    position: relative;
    padding-top: 16px;
    padding-bottom: 15px;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: map-get($colors, "sgray");
    }

    &:last-child {
      &::before {
        background-color: transparent;
      }
    }
  }

  &__card-input {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  &__card-svg {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 14px;
    z-index: 1;
    transform: translate(0, -50%);
  }

  &__icon-close {
    position: absolute;
    top: 8px;
    right: 5px;
    font-size: 16px;
    font-weight: 500;
    width: 14px;
    height: 14px;

    &._default-card {
      top: 30px;
    }
  }
}

.status {
  &-widget {
    min-height: 56px;
    background: #e35531;
    padding: 10px 35px 10px 45px;
    line-height: 1.4;
    margin-bottom: 16px;
    vertical-align: top;
    color: #fff !important;

    &__icon-stage,
    &__icon-arrow {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      line-height: 24px;
      display: flex;
      align-content: center;
      justify-content: center;
    }

    &__icon-stage {
      font-size: 20px;
      left: 12px;
      top: 0;
      transform: translateY(50%);
    }

    &__icon-arrow {
      right: 6px;
      font-size: 14px;
    }

    &._discount {
      padding-left: 7px;

      img {
        flex-shrink: 0;
        width: 44px;
        height: 44px;
        margin-right: 8px;
      }
    }
  }

  &-icon {
    border: 1px solid map-get($colors, "teb");
    font-size: 24px;
    color: #24594b;

    &._active {
      color: #000;
      border: 0;
      background: #24594b;
    }

    &._failed {
      color: #fff;
      border: 0;
      background: #E5243F;
    }

    &._checked {
      color: #999fa4;
      background: map-get($colors, "bg");
      border-color: transparent;

      &.icon-check-long {
        &:before {
          content: $icon-check-long !important;
        }
      }
    }

    &.icon-clock {
      &:before {
        content: $icon-clock-outline !important;
      }
    }
  }
}

.rate {
  &-star {
    &:before {
      transition: all .3s;
      color: #cccfd1;
    }

    &:after {
      content: $icon-star-rounded;
      opacity: 0;
      transition: opacity .3s;
      color: #ffcd00;
      position: absolute;
    }

    &._active {
      &:before {
        color: #fff;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.description {
  &__skeleton {
    position: absolute;
    color: transparent;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
  }
}

.empty-cart {
  transform: translate(20px, 0);

  &__small {
    transform: translate(10px, 0);
  }
}

.splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  circle {
    fill: #24594b;
  }
}

.checkout {
  &-comment {
    height: 44px;

    &:empty {
      padding: 14px 12px;
      overflow: hidden;
    }

    &:focus,
    &:not(:empty) {
      height: 80px;
    }
  }

  &-instruction-wrapper {
    gap: 8px;

    .checkout-instruction + .checkout-instruction {
      margin: 0;
    }
  }

  &-instruction {
    display: inline-flex;
    align-items: center;
    padding: 0 14px;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    position: relative;
    vertical-align: top;

    @media (min-width: 1180px) and (max-width: 1259px) {
      text-align: center;
      max-width: 125px;
      white-space: normal;
    }

    @media (min-width: 1105px) and (max-width: 1180px) {
      text-align: center;
      max-width: 100px;
      font-size: 13px;
      white-space: normal;

      &._points {
        max-width: 100%;
      }
    }

    @media (min-width: 501px) and (max-width: 1105px) {
      text-align: center;
      max-width: 80px;
      font-size: 11px;
      white-space: normal;

      &._points {
        max-width: 100%;
      }
    }

    & + .checkout-instruction {
      margin-left: 8px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      pointer-events: none;
    }

    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 8px;
      background: #24594b;
      opacity: 0.2;
      transition: opacity 0.3s;
    }

    &:after {
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      background: #fff;
      border-radius: 7px;
      z-index: 2;
    }

    > * {
      z-index: 3;
    }

    &__check {
      position: absolute;
      top: -5px;
      right: -6px;
      transform: rotate(-90deg) scale(0);
      transition: all 0.3s;
      opacity: 0;
      pointer-events: none;
      background: #24594b;
    }

    &._disable {
      color: map-get($colors, 'gray');
      &:before {
        background: map-get($colors, 'gray');
      }
    }

    &._active {
      &:before {
        opacity: 1;
      }

      .checkout-instruction__check {
        transform: rotate(0deg) scale(1);
        opacity: 1;
      }
    }

    &._method {
      opacity: 0.4;
      transition: opacity 0.6s;

      &:before {
        transition: none;
      }

      &._active {
        opacity: 1;
      }

      &._gpay {
        padding: 0;
        margin-left: 12px !important;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    &._points {
      height: 63px;
      padding: 22px 0;

      &._disabled {
        pointer-events: none;
      }
    }
  }
}

.referral {
  &-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-image: url($img_dir + "referral_banner.png");
    background-position: center center;
    background-size: cover;
    padding: 16px;
    font-size: 22px;
    text-align: center;
    color: #fff !important;
    font-weight: 500;
    height: 105px;
    line-height: 1.3;
  }

  &-code-box {
    border: 2px dashed #f44719;
    font-size: 21px;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    color: #0091da;
    border-radius: 8px;
    font-weight: 500;
  }
}

.jiffy-points {
  &__terms {
    height: 70px;
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 40%;

    & + .jiffy-points__list-item {
      margin-left: 8px;
    }
  }
}

.icon {
  &__copy {
    position: relative;
    width: 24px;
    height: 24px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      pointer-events: none;
    }

    &:before {
      width: 14px;
      height: 16px;
      border-top: 2px solid currentColor;
      border-left: 2px solid currentColor;
      top: 1px;
      left: 2px;
    }

    &:after {
      width: 15px;
      height: 18px;
      border: 2px solid currentColor;
      top: 5px;
      left: 6px;
    }
  }

  &__number {
    font-family: var(--override-font-value-serif-medium, $font_value_serif_medium);
    font-weight: 700;
    border: 2px solid black;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #000;
  }
}

.grecaptcha-badge {
  display: none;
}

.points-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-6deg);
  transform-origin: 50% 50%;

  .points-text {
    font-size: 50px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .jiffy-points__list-item + .jiffy-points__list-item {
    margin: 0;
  }
}

@media (min-width: $iphone_11_pro_max_width) {
  .delivery-address {
    &__title {
      font-size: 24px;
      line-height: 29px;
    }

    &__announce {
      margin-top: 12px;
      font-size: 16px;
      line-height: 22px;
    }

    &__zip-text-caption {
      font-size: 14px;
    }

    &__zip-text {
      font-size: 12px;
    }

    &__address-button {
      height: 55px;
      font-size: 18px;
    }
  }

  .search {
    &-bar {
      min-height: 40px;

      &__icon-search {
        width: 40px;
        height: 40px;
      }

      &__wrap {
        height: 56px;
      }

      .input-text {
        padding: 0 48px;
        height: 40px;
      }
    }
  }

  .category {
    &-title {
      margin-top: 20px;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media (max-width: $layout_max_width) {
  .category {
    &-list__col._2,
    &-list__col._3 {
      .category-item__name {
        font-size: 3.7vw;
      }
    }

    &-item {
      &__name {
        font-size: 3.4vw;
      }
    }
  }
}

@media (max-width: 340px) {
  .product-card__link {
    padding: 0 14px;
  }

  .onboarding {
    &-slide {
      &__text {
        font-size: 40px;
      }
    }
  }
}

@media (max-width: $iphone_se_5_width) {
  .delivery-address {
    &__cover {
      padding: 18px 21px 21px;
    }

    &__title {
      font-size: 19px;
      line-height: 22px;
    }

    &__announce {
      margin-top: 12px;
      font-size: 12px;
      line-height: 17px;
    }

    &__address-button {
      height: 42px;
      font-size: 14px;
    }
  }

  .product {
    &-item {
      &__low-stock {
        font-size: 11px;
      }
    }

    &-card {
      &__link {
        font-size: 12px;
        line-height: 18px;
      }

      .product-item__name {
        font-size: 14px;
        line-height: 19px;
      }

      .product-item__price {
        font-size: 27px;
        line-height: 32px;
      }
    }

    &-list {
      &__header {
        font-size: 14px;
      }
    }
  }

  .search {
    &-bar {
      min-height: 37px;

      &__icon-search {
        width: 37px;
        height: 37px;
      }

      .input-text {
        padding: 0 37px;
        height: 37px;
      }
    }
  }

  .category {
    &-title {
      margin-top: 16px;
      font-size: 13px;
      line-height: 17px;
    }
  }

  .status-widget._discount {
    .icon + div {
      margin-top: -5px;

      .fs-12 {
        line-height: 12px !important;
      }
    }
  }
}

@media (max-height: 600px) {
  .photo-team {
    display: none !important;
  }
}

@media (max-width: 356px) {
  .service-fee-status {
    display: none !important;
  }

  .service-fee-status.short {
    display: inline-block !important;
  }
}
